"use client";

import { getTranslation } from "@projectluna/lib/saleor/intl";

import { HoverImage } from "@/components/HoverImage";
import { Link } from "@/components/Link";
import { type NextImageProps } from "@/components/NextImage";
import { useLocalizedPaths } from "@/lib/paths/client";
import { trackActivity } from "@/lib/tracking/handlers";
import { type WithHTMLProp } from "@/lib/types";

import { type InjectedProps } from "../types";

import { carouselCardSizes } from "@/styles/lib";

export type ImageProps<Injected = false> = InjectedProps<
  Injected,
  Pick<NextImageProps, "sizes" | "priority" | "loading">
> &
  WithHTMLProp<"className">;

export const Image = ({ className, ...props }: ImageProps) => {
  const { product, analyticsName, ...imageProps } = props as ImageProps<true>;
  const paths = useLocalizedPaths();

  return (
    <Link
      className={className}
      href={paths.product.asPath({
        slug: product.slug,
      })}
      onClick={() =>
        trackActivity.viewProductFromListing(
          analyticsName || "Product Card",
          product,
          product?.variants ?? []
        )
      }
    >
      <HoverImage
        alt={getTranslation("name", product)}
        className="card-image"
        media={product.media}
        sizes={carouselCardSizes}
        {...imageProps}
      />
    </Link>
  );
};
