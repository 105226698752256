import { type TaxedMoneyFragment } from "@projectluna/codegen/graphql/fragments/generated";

import { Price, type PriceProps } from "@/components/Price";
import { getPrice, getUndiscountedPrice } from "@/lib/price";
import type { MaybeWithoutTypename } from "@/lib/types";

interface TaxedPriceProps
  extends Omit<PriceProps, "price" | "priceUndiscounted" | "discount"> {
  displayGrossPrices?: boolean;
  hasDiscount?: boolean;
  price: MaybeWithoutTypename<TaxedMoneyFragment>;
  priceUndiscounted?: MaybeWithoutTypename<TaxedMoneyFragment>;
}

export const TaxedPrice = ({
  onSale,
  price,
  priceUndiscounted,
  hasDiscount,
  displayGrossPrices,
  ...props
}: TaxedPriceProps) => {
  const targetPrice = getPrice(price, displayGrossPrices);
  const targetPriceUndiscounted = getUndiscountedPrice(
    priceUndiscounted,
    displayGrossPrices
  );

  return (
    <Price
      hasDiscount={!!hasDiscount}
      price={targetPrice}
      priceUndiscounted={targetPriceUndiscounted}
      onSale={onSale}
      {...props}
    />
  );
};
