"use client";

import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { type EmblaCarouselType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import React, { Children, useCallback, useEffect, useState } from "react";

import { type WithChildren } from "@/lib/types";

import { cn } from "@/styles/lib";

type SliderCallback = Parameters<EmblaCarouselType["on"]>[1];
type SliderEvents = Parameters<EmblaCarouselType["on"]>[0];

export const Carousel = ({ children }: WithChildren) => {
  const [emblaRef, sliderApi] = useEmblaCarousel({
    align: "start",
    loop: true,
    skipSnaps: true,
  });
  const [{ canScrollNext, canScrollPrev }, setSliderState] = useState<{
    canScrollNext: boolean;
    canScrollPrev: boolean;
  }>({
    canScrollNext: false,
    canScrollPrev: false,
  });

  const handleScrollNext = useCallback(
    () => sliderApi?.scrollNext(),
    [sliderApi]
  );

  const handleScrollPrev = useCallback(
    () => sliderApi?.scrollPrev(),
    [sliderApi]
  );

  useEffect(() => {
    if (sliderApi) {
      const handler: SliderCallback = api => {
        setSliderState({
          canScrollNext: api.canScrollNext(),
          canScrollPrev: api.canScrollPrev(),
        });
      };

      const events: SliderEvents[] = [
        "init",
        "slidesInView",
        "resize",
        "select",
        "settle",
        "reInit",
      ];

      events.forEach(event => sliderApi.on(event, handler as any));

      return () =>
        events.forEach(event => sliderApi.off(event, handler as any));
    }
  }, [sliderApi]);

  return (
    <div className="relative w-full">
      <div className="overflow-hidden" ref={emblaRef}>
        <div
          className={cn("flex min-w-0", "-ml-4 md:-ml-3 xl:-ml-4 2xl:-ml-6")}
        >
          {Children.map(children, child => (
            <div
              className={cn(
                "flex-[0_0_calc(100%)] sm:flex-[0_0_calc(100%/2)] md:flex-[0_0_calc(100%/3)] lg:flex-[0_0_calc(100%/4)]",
                "pl-4 md:pl-3 xl:pl-4 2xl:pl-6",
                "cursor-pointer"
              )}
            >
              {child}
            </div>
          ))}
        </div>
      </div>
      {canScrollPrev && (
        <div
          className="carousel-navigation -left-7"
          onClick={handleScrollPrev}
        >
          <ChevronLeftIcon />
        </div>
      )}
      {canScrollNext && (
        <div
          className="carousel-navigation -right-7"
          onClick={handleScrollNext}
        >
          <ChevronRightIcon />
        </div>
      )}
    </div>
  );
};
