import { Children, type ComponentProps, Fragment } from "react";

import { type WithChildren } from "@/lib/types";

import { cn } from "@/styles/lib";

type HoverImageBoxProps = WithChildren & ComponentProps<"div">;

export const HoverImageBox = ({
  className,
  children,
  ...props
}: HoverImageBoxProps) => {
  const childrenElements =
    // @ts-ignore
    children?.type === Fragment
      ? // @ts-ignore
        children.props?.children?.filter?.(Boolean)
      : children;
  const hasMultipleImages = Children.count(childrenElements) > 1;

  return (
    <div
      className={cn(
        "relative",
        "h-full",
        "w-full",
        "overflow-clip",
        "[&>img]:aspect-inherit",
        "[&>img]:scale-100",
        "[&>img]:rotate-[0.001deg]",
        "[&>img]:transition-all",
        "[&>img]:duration-700",
        "[&>img]:ease-in-out",
        "[&>img]:!translate-z-0",
        "[&>img]:absolute",
        "[&>img]:object-center",
        "[&>img]:object-cover",
        "[&>img]:w-full",
        "[&>img]:h-full",
        "hover:[&>img:last-of-type]:origin-center",
        "hover:[&>img:last-of-type]:opacity-90",
        "hover:[&>img:last-of-type]:scale-[1.020]",
        "hover:[&>img:last-of-type]:rotate-[0.001deg]",
        {
          "hover:[&>img:first-of-type]:opacity-0 [&>img:last-of-type]:opacity-0":
            hasMultipleImages,
          "aspect-square": !className?.includes("aspect"),
        },
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};
