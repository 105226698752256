import { type ProductPriceFragment } from "@projectluna/codegen/graphql/fragments/generated";

import { Price } from "@/components/Price";
import { TaxedPrice } from "@/components/TaxedPrice";
import { useLocalizedFormatter } from "@/lib/intl/client";
import type { MaybeWithoutTypename, WithHTMLProp } from "@/lib/types";

type PriceRangeProps = WithHTMLProp<"className"> & {
  pricing: MaybeWithoutTypename<ProductPriceFragment>;
};

export const PriceRange = ({ pricing, className }: PriceRangeProps) => {
  const formatter = useLocalizedFormatter();
  const formatPriceRange = formatter.priceRange;

  if (
    pricing?.priceRange?.start?.gross.amount ===
    pricing?.priceRange?.stop?.gross.amount
  ) {
    return (
      <TaxedPrice
        className={className}
        price={pricing?.priceRange?.stop}
        priceUndiscounted={pricing?.priceRangeUndiscounted?.stop}
        onSale={pricing?.onSale}
      />
    );
  }

  if (!pricing?.onSale && !pricing?.discount) {
    return (
      <Price.RegularPrice className={className}>
        {formatPriceRange({
          start: pricing?.priceRange?.start?.gross.amount,
          stop: pricing?.priceRange?.stop?.gross.amount,
          currency: pricing?.priceRange?.stop?.gross.currency,
        })}
      </Price.RegularPrice>
    );
  }

  return (
    <>
      <Price.UndiscountedPrice className={className}>
        {formatPriceRange({
          start: pricing?.priceRangeUndiscounted?.start?.gross.amount,
          stop: pricing?.priceRangeUndiscounted?.stop?.gross.amount,
          currency: pricing?.priceRangeUndiscounted?.stop?.gross.currency,
        })}
      </Price.UndiscountedPrice>
      <Price.DiscountedPrice className={className}>
        {formatPriceRange({
          start: pricing?.priceRange?.start?.gross.amount,
          stop: pricing?.priceRange?.stop?.gross.amount,
          currency: pricing?.priceRange?.stop?.gross.currency,
        })}
      </Price.DiscountedPrice>
    </>
  );
};
