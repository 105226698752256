"use client";

import { getTranslation } from "@projectluna/lib/saleor/intl";

import { Link } from "@/components/Link";
import { useLocalizedPaths } from "@/lib/paths/client";
import { trackActivity } from "@/lib/tracking/handlers";

import { type InjectedProps } from "../types";

import { cn } from "@/styles/lib";

export const Name = (props: InjectedProps<false>) => {
  const { product, analyticsName, variant } = props as InjectedProps<true>;
  const paths = useLocalizedPaths();

  return (
    <div
      className={cn("pointer-events-[all] truncate font-bold", {
        "text-xs md:text-sm": variant === "sm",
      })}
    >
      <Link
        href={paths.product.asPath({
          slug: product.slug,
        })}
        onClick={() =>
          trackActivity.viewProductFromListing(
            analyticsName || "Product Card",
            product,
            product?.variants ?? []
          )
        }
      >
        <h2>{getTranslation("name", product)}</h2>
      </Link>
    </div>
  );
};
