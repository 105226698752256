import { type ProductMediaFragment } from "@projectluna/codegen/graphql/fragments/generated";

import { HoverImageBox } from "@/components/HoverImageBox";
import { ImagePlaceholder } from "@/components/ImagePlaceholder";
import { NextImage, type NextImageProps } from "@/components/NextImage";
import type { Maybe, WithHTMLProp } from "@/lib/types";

export type HoverImageProps = WithHTMLProp<"className"> & {
  alt: string;
  media: Maybe<Pick<ProductMediaFragment, "alt" | "thumbnail">[]>;
} & Omit<NextImageProps, "src" | "alt">;

export const HoverImage = ({
  alt,
  media,
  className,
  style,
  ...imageProps
}: HoverImageProps) => {
  const hasMultipleImages = !!media?.[1];

  return (
    <HoverImageBox className={className}>
      {media?.length ? (
        <>
          <NextImage
            fill
            alt={media[0].alt || alt}
            src={media[0].thumbnail}
            style={{ objectFit: "cover", ...style }}
            {...imageProps}
          />
          {hasMultipleImages && (
            <NextImage
              fill
              alt={media[1].alt || alt}
              fallbackPlaceholder={false}
              priority={false}
              src={media[1].thumbnail}
              style={{ objectFit: "cover", ...style }}
              {...imageProps}
            />
          )}
        </>
      ) : (
        <ImagePlaceholder />
      )}
    </HoverImageBox>
  );
};
